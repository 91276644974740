import React from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from '@agria/theme/src/styles/tokens';
import { getColorTones } from '@agria/theme/src/utils';
import { SitewideMessage } from '@agria/theme/src/components/sitewideMessage/SitewideMessage';
import { SimpleHeader } from '@agria/theme/src/components/headers/simpleHeader/SimpleHeader';
import { useSiteCountry } from '@agria/theme/src/hooks/useSiteCountry';
import { useSiteMetadata } from '@agria/theme/src/hooks/useSiteMetadata';
import Header from '../slices/Header';
import Footer from '../slices/Footer';

export const BaseLayout = ({
  brand = 'agria',
  location,
  children,
}: React.PropsWithChildren<{ brand?: 'agria' | 'BASC' | 'GCCF' }>) => {
  let newTheme = { ...theme };
  const { contactNumber, companyName, useSimpleHeader } = useSiteMetadata();
  const { isIe } = useSiteCountry();

  if (brand === 'BASC') {
    const bascFontFamily = 'Roboto Slab, serif';

    const bascTYpography = {
      fontFamily: bascFontFamily,
      fontWeight: 400,
    };

    const bascTYpographyBold = {
      fontFamily: bascFontFamily,
      fontWeight: 600,
    };

    newTheme = {
      ...newTheme,
      colors: {
        ...newTheme.colors,
        ...getColorTones('#078463', 'brandOne'),
        ...getColorTones('#014711', 'brandTwo'),
        utilityBackground: '#E6F3EF',
        utilityText: '#3C4858',
      },
      typography: {
        ...newTheme.typography,
        headingXLarge: {
          ...newTheme.typography.headingXLarge,
          ...bascTYpographyBold,
        },
        headingLarge: {
          ...newTheme.typography.headingLarge,
          ...bascTYpographyBold,
        },
        headingMedium: {
          ...newTheme.typography.headingMedium,
          ...bascTYpographyBold,
        },
        headingSmall: {
          ...newTheme.typography.headingSmall,
          ...bascTYpographyBold,
        },
        bodyLarge: {
          ...newTheme.typography.headingSmall,
          ...bascTYpography,
        },
        bodyLargeBold: {
          ...newTheme.typography.headingSmall,
          ...bascTYpographyBold,
        },
        quote: {
          ...newTheme.typography.headingSmall,
          ...bascTYpographyBold,
        },
      },
    };
  }

  if (brand === 'GCCF') {
    newTheme = {
      ...newTheme,
      colors: {
        ...newTheme.colors,
        ...getColorTones('#452683', 'brandOne'),
        ...getColorTones('#C29C5E', 'brandTwo'),
        utilityBackground: '#ECE9F3',
        utilityText: '#452683',
      },
    };
  }

  return (
    <ThemeProvider
      theme={brand === null || brand === 'agria' ? theme : newTheme}
    >
      {brand === 'BASC' && (
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600&display=swap');
        </style>
      )}
      {useSimpleHeader ? (
        <SimpleHeader contactNumber={contactNumber} companyName={companyName} />
      ) : (
        <Header brand={brand} location={location} />
      )}

      {!isIe && (
        <SitewideMessage
          message="We'll be performing essential maintenance on Tuesday 9th July, from 9pm to midnight. Please come back outside of these times to cover your pet."
          startTime="2024-07-09T21:00:00"
          endTime="2024-07-09T11:59:00"
        />
      )}
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  );
};
