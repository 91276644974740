import React from 'react';
import { graphql, SliceComponentProps, useStaticQuery } from 'gatsby';
import { Footer as FooterComponent } from '@agria/theme/src/components/footers/footer/Footer';

// const Footer = ({
//   data: { socialLinks, footerData },
// }: SliceComponentProps<Queries.FooterSliceQuery>) => {

const Footer = () => {
  const { socialLinks, footerData } = useStaticQuery(graphql`
    query FooterQuery {
      socialLinks: umbracoSocialLinksType {
        facebook
        twitter
        linkedIn
        instagram
        youTube
      }
      footerData: umbracoFooterType {
        navigationColumns {
          block {
            components {
              ... on UMBRACO_FooterMenuComponentType {
                links {
                  ...Link
                }
              }
            }
          }
        }
        secondaryNavigation {
          ...Link
        }
        smallText
      }
    }
  `);

  return (
    <FooterComponent
      navigationColumns={footerData?.navigationColumns[0]?.block?.components}
      smallText={footerData?.smallText}
      socialLinks={socialLinks}
      secondaryNavigation={footerData?.secondaryNavigation}
    />
  );
};

export default Footer;
